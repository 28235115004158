























































































import {
	Component,
	Vue
} from 'vue-property-decorator';
import {
	signUp,
	getSmsCode
} from "../../api"
import vSelect from '../../components/v-select.vue'
@Component({
	components: {
		vSelect
	}
})

export default class extends Vue {
	private showMsg = false
	private msg = ''
	private isPlay = false
	private isPlay1 = false;
	private tabs = [{
		value: 1,
		text: '了解黑谷'
	}, {
		value: 2,
		text: '成功案例'
	}, {
		value: 3,
		text: '客户评价'
	}]
	private modeList = [
	{
		value: 2,
		text: '共享商圈',
		icon:require('../../assets/images/spread/gxsq.png'),
		iconon:require('../../assets/images/spread/gxsq_on.png'),
		subTitle: '把1个人的生意变成1群人的生意',
		content: ['区别于传统异业联盟', '把门店客源快速翻倍增收', '为门店快速引流让一群人帮你拓客'],
		videoUrl: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/171f2afc-17920c5f65f-0005-502e-0e5-a9c95.mp4',
		poster: '/img/business.abef2661.jpg'
	}, {
		value: 3,
		text: '抖音运营',
		icon:require('../../assets/images/spread/dyyy.png'),
		iconon:require('../../assets/images/spread/dyyy_on.png'),
		subTitle: '快速学习互联网营销工具，最低成本引爆客源',
		content: ['客户定位更精准', '引流活动有效到达', '沉睡客户重复唤醒'],
		videoUrl: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/3e62afd8-17920bfc461-0005-502e-0e5-a9c95.mp4',
		poster: '/img/trill.159d0ea7.jpg'
	}, {
		value: 4,
		text: '员工合伙',
		icon:require('../../assets/images/spread/yghh.png'),
		iconon:require('../../assets/images/spread/yghh_on.png'),
		subTitle: '用机制管理替代传统管理，1招点燃员工积极性',
		content: ['稳定优秀的员工', '点燃每一个员工的服务热情', '让每个员工都和老板一样创造业绩'],
		videoUrl: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/2e828f64-17920cb29da-0005-502e-0e5-a9c95.mp4',
		poster: '/img/staff.7c963154.jpg'
	}]
	private modeIndex = 0
	private industrys = [{
		value: 1,
		text: '汽修',
		icon:require('../../assets/images/spread/qx.png'),
		iconon:require('../../assets/images/spread/qx_on.png'),
		poster:'/assets/images/poster.png',
		videoUrl: 'https://video.xmheigu.com/df19cb88176b4ea7ae2dad22f53f327f/c3ae4a65140f493b989529aa134b5c8c-9e2d15be1ddefecf84f6add3f5235e9c-hd.mp4',
	}, {
		value: 2,
		text: '美业',
		icon:require('../../assets/images/spread/my.png'),
		iconon:require('../../assets/images/spread/my_on.png'),
		videoUrl: 'https://video.xmheigu.com/0ea28086ed91447eae7d440d10d301a0/634e4db6f1244d028a18f8f57c690446-d2092d8208d52a1df9acdce255fefe5e-hd.mp4',
	}, {
		value: 3,
		text: '餐饮',
		icon:require('../../assets/images/spread/cy.png'),
		iconon:require('../../assets/images/spread/cy_on.png'),
		videoUrl: 'https://video.xmheigu.com/798865bafc69410bacbf36e9fc41b42f/ffd8156ff53f4f52b5abf6f1b0d39bfe-c90155f58fd18473ec41b2244b5196fa-hd.mp4',
	}, {
		value: 4,
		text: '教育',
		icon:require('../../assets/images/spread/jy.png'),
		iconon:require('../../assets/images/spread/jy_on.png'),
		videoUrl: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/506922d8-17bee26ce5f-0005-502e-0e5-a9c95.mp4'
	}]
	private industryIndex = 0;
	private Message: any
	private groupName = ''
	private industryCode = ''
	private industryName = '';
	private noteCode = ''
	private mobile = ''
	private options = [{
			value: 0,
			name: "汽车"
		},
		{
			value: 1,
			name: "美业"
		},
		{
			value: 2,
			name: "餐饮"
		},
		{
			value: 4,
			name: "教育"
		},
		// {
		// 	value: 99,
		// 	name: "其他"
		// }
	]
	private timer = 0;
	navigete(item: any) {
		switch (item.value) {
			case 1:
				this.$router.push('/home')
				break;
			case 2:
				(document.getElementById('successCase') || (window as any)).scrollIntoView({
					block: 'start',
					behavior: 'smooth'
				})
				break;
			default:
				(document.getElementById('customerEvaluate') || (window as any)).scrollIntoView({
					block: 'start',
					behavior: 'smooth'
				})
				return;

		}
	}
	switchMode(index: any) {
		this.modeIndex = index;
		let video = document.getElementsByTagName('video')[0];
		video.pause();
		this.isPlay = false;
		document.getElementsByTagName('video')[1].pause();
	}
	setPlay() {
		let video = document.getElementsByTagName('video')[0];
		this.isPlay = !this.isPlay;
		if(this.isPlay)video.play();
		if(!this.isPlay)video.pause();
		document.getElementsByTagName('video')[1].pause();
	}
	switchIndustry(index:number){
		this.industryIndex = index;
		let video = document.getElementsByTagName('video')[1];
		video.pause();
		this.isPlay1 = false;
		document.getElementsByTagName('video')[0].pause();
	}
	setPlay1() {
		let video = document.getElementsByTagName('video')[1];
		document.getElementsByTagName('video')[0].pause();
		video.play();
		this.isPlay1 = !this.isPlay1;
	}
	select(value: string) {
		this.industryCode = value
	}
	interval() {
		let interval = setTimeout(() => {
			this.timer = this.timer - 1
			if (this.timer > 0) {
				this.interval()
			} else {
				clearTimeout(interval)
			}
		}, 1000)
	}

	async getSmsCode() {
		if (!this.mobile) {
			this.showMsg = true;
			this.showToast('请填写手机号')
			return;
		}
		if (this.timer == 0) {
			const result = await getSmsCode(this.mobile).catch((result)=>{
			this.showToast(result.message);
		})
			if (result) {
				this.timer = 30
				this.interval()
				this.showToast('发送成功')
			}
		}
	}
	async submit() {
		if (!this.groupName) {
			this.showToast('请填写姓名')
			return;
		}
		if (!this.mobile) {
			this.showToast('请填写手机号')
			return;
		}
		if (!this.noteCode) {
			this.showToast('请填写验证码')
			return;
		}
		if (this.industryCode===''||this.industryCode==undefined) {
			this.showToast('请选择行业')
			return;
		}
		let openId= this.$route.query.openId || this.$route.query.openid;
		let sourceCode=openId? 79:122;
		const result = await signUp({
			groupName: this.groupName,
			industryCode: this.industryCode,
			mobile: this.mobile,
			noteCode: this.noteCode,
			sourceCode: sourceCode,
			openId:openId
		}).catch((result)=>{
			this.showToast(result.message);
		})
		console.log(result);
		if (result) {
			this.industryCode = '';
			this.groupName = '';
			this.mobile = '';
			this.timer = 0;
			this.noteCode = '';
			this.showToast('提交成功')
			return;
		}
	}
	showToast(msg: any) {
		this.showMsg = true;
		this.msg = msg;
		setTimeout(() => {
			this.showMsg = false;
		}, 2000)
	}
}
